/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Button Styles */
.btn-taxPrism {
  color: white;
  background-color: #097c39;
  border-radius: 10px;
}

.btn-taxPrism:hover {
  color: white;
  background-color: #70c484;
  border-radius: 10px;
}

.btn-taxPrism-outline {
  color: #097c39;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #097c39;
}

.btn-taxPrism-outline:hover {
  color: #70c484;
  border: 1px solid #70c484;
}

.btn-taxPrism-approve {
  color: white;
  border-radius: 10px;
  background-color: #097c39;
}
/*  */

.tp-Center {
  text-align: center;
}

.padding-all05em {
  padding: 0.5em;
}

.padding-all1em {
  padding: 1em;
}

.padding-all2em {
  padding: 2em;
}

/* Sections */
.tpSectionHeader {
  background-color: #097c39;
  color: white;
}

.tpSectionHeader h2 {
  font-weight: 300;
}

.tpSectionHeader h5 {
  font-weight: 100;
}

.tpStatementHeader {
  font-weight: 100;
  /* background-color: #097c39;
  color: white; */
}

/* Customer Row */
.tp-customer-row {
  background-color: #f0edee;
  font-weight: 200;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.tp-customer-row .row {
  padding: 1em;
}

.tp-DashboardTitle {
  font-size: 1.2em;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.section {
  @apply m-1 bg-gray-50 p-1 rounded drop-shadow;
}

.sectionMobile {
  @apply m-1 bg-gray-50 p-1 rounded drop-shadow;
}

.btn {
  @apply bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded uppercase;
}
.btnnobg {
  @apply  text-white py-2 px-4 rounded uppercase;
}

.statusbtn{
 @apply m-2 p-4 bg-gray-100 shadow-lg rounded cursor-pointer hover:ring-2 hover:ring-blue-400 transition delay-150 ease-in-out;
}

.subscriptionBtn{
  @apply uppercase bg-white border border-black p-2 m-2 shadow rounded font-medium hover:shadow-black hover:mt-3 w-60
}

.dialog {
  transition: opacity 0.25s ease;
  @apply fixed top-0  bg-gray-100  w-screen h-full;
}

.buttonNone {
  @apply rounded uppercase;
}
